
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../../lib/util'
    import AbpBase from '../../../../lib/abpbase'
    import Action from '../../../../store/entities/jobcontext/action'

    @Component
    export default class DocumentForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        action: Action = new Action();

        created() {
        }

        save() {
            (this.$refs.actionForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'action/update' : 'action/create',
                        data: this.action
                    });
                    (this.$refs.actionForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.actionForm as any).resetFields();
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                if (this.toEdit) {
                    this.action = Util.extend(true, {}, this.$store.state.action.editAction);
                } else {
                    this.action = new Action();
                }
            }
        }

        actionRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
        }
    }
