
import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '@/lib/util'
import PageRequest from '@/store/entities/page-request'
import ActionForm from './action-form.vue'
import SortingModel from "@/store/entities/sortingmodel";
import AbpBase from "@/lib/abpbase";
import ActionDto from "@/store/entities/jobcontext/action";


@Component({
  components: {ActionForm}
})
export default class Action extends AbpBase {
  edit() {
    this.editModalShow = true;
  }

  pagerequest: PageRequest = new PageRequest();

  createModalShow: boolean = false;
  editModalShow: boolean = false;

  get list() {
    return this.$store.state.action.list;
  };

  async search() {
    this.$store.commit('action/setCurrentPage', 1);
    await this.getPage();
  }

  get loading() {
    return this.$store.state.action.loading;
  }

  create() {
    this.$store.commit('action/edit', new ActionDto());
    this.createModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit('action/setCurrentPage', page);
    this.getPage();
  }

  pageSizeChange(pageSize: number) {
    this.$store.commit('action/setPageSize', pageSize);
    this.getPage();
  }

  async getPage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: 'action/getAll',
      data: this.pagerequest
    })
  }

  get pageSize() {
    return this.$store.state.action.pageSize;
  }

  get totalCount() {
    return this.$store.state.action.totalCount;
  }

  get currentPage() {
    return this.$store.state.action.currentPage;
  }

  async changeSort(data) {
    if (this.pagerequest.sorting == null) {
      this.pagerequest.sorting = [];
    }
    let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
    if (item == null) {
      let sortingModel = new SortingModel();
      sortingModel.fieldName = data.key;
      sortingModel.isDescendingDirection = data.order === "desc";
      this.pagerequest.sorting.push(sortingModel)
    } else {
      item.isDescendingDirection = data.order === "desc";
    }
    await this.getPage()
  }

  columns = [{
    title: this.L('Name'),
    key: 'name',
    sortable: 'custom'
  }, {
    title: this.L('Code'),
    key: 'code',
    sortable: 'custom'
  }, {
    title: this.L('Actions'),
    key: 'Actions',
    width: 150,
    render: (h: any, params: any) => {
      return h('div', [
        h('Button', {
          props: {
            type: 'primary',
            size: 'small'
          },
          style: {
            marginRight: '5px'
          },
          on: {
            click: () => {
              this.$store.commit('action/edit', params.row);
              this.edit();
            }
          }
        }, this.L('Edit')),
        h('Button', {
          props: {
            type: 'error',
            size: 'small'
          },
          on: {
            click: async () => {
              this.$Modal.confirm({
                title: this.L('Tips'),
                content: this.L('Delete Document Confirm'),
                okText: this.L('Yes'),
                cancelText: this.L('No'),
                onOk: async () => {
                  await this.$store.dispatch({
                    type: 'action/delete',
                    data: params.row
                  })
                  await this.getPage();
                }
              })
            }
          }
        }, this.L('Delete'))
      ])
    }
  }]

  async created() {
    await this.getPage();
  }
}
